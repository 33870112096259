angular.module('LeasePilot').directive('leaseMenu', [
  '$rootScope',
  '$interval',
  function($rootScope, $interval) {
    return {
      restrict: 'E',
      transclude: true,
      templateUrl: 'lease_menu.html',
      link: function(scope, element, attrs) {
        const menuContent = element[0].querySelector('.menu-scrollable-content');
        let scrollInterval;
        
        function updateScrollState() {
          scope.$apply(function() {
            scope.scrollTop = menuContent.scrollTop;
            scope.scrollHeight = menuContent.scrollHeight;
            scope.clientHeight = menuContent.clientHeight;
          });
        }

        function startScroll(direction) {
          stopScroll();
          
          scrollInterval = $interval(function() {
            menuContent.scrollTop += direction * 5; // Adjust speed by changing this number
          }, 16); // ~60fps
        }

        function stopScroll() {
          if (scrollInterval) {
            $interval.cancel(scrollInterval);
            scrollInterval = null;
          }
        }
        
        menuContent.addEventListener('scroll', updateScrollState);

        const topIndicator = element[0].querySelector('.scroll-indicator.top');
        const bottomIndicator = element[0].querySelector('.scroll-indicator.bottom');

        if (topIndicator) {
          topIndicator.addEventListener('mouseenter', () => startScroll(-1));
          topIndicator.addEventListener('mouseleave', stopScroll);
        }

        if (bottomIndicator) {
          bottomIndicator.addEventListener('mouseenter', () => startScroll(1));
          bottomIndicator.addEventListener('mouseleave', stopScroll);
        }

        const observer = new MutationObserver(function(mutations) {
          mutations.forEach(function(mutation) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
              const isOpen = mutation.target.classList.contains('lp-menu--open');
              if (isOpen) {
                updateScrollState();
                setTimeout(updateScrollState, 0);
              }
            }
          });
        });

        const menuElement = element[0].querySelector('.lp-menu');
        if (menuElement) {
          observer.observe(menuElement, {
            attributes: true,
            attributeFilter: ['class']
          });
        }

        scope.scrollTop = 0;
        scope.scrollHeight = 0;
        scope.clientHeight = 0;

        scope.$on('$destroy', function() {
          observer.disconnect();
          stopScroll();

          if (topIndicator) {
            topIndicator.removeEventListener('mouseenter', () => startScroll(-1));
            topIndicator.removeEventListener('mouseleave', stopScroll);
          }
          
          if (bottomIndicator) {
            bottomIndicator.removeEventListener('mouseenter', () => startScroll(1));
            bottomIndicator.removeEventListener('mouseleave', stopScroll);
          }
        });
      },
    };
  },
]);
